const defaultState = {

}

export default function trackReducer(state = defaultState, action){
    switch (action.type) {
        
        default:
            return state
    }
}
